<template lang="pug">
  div(:class="{'is-mine': isMine}").wrap-item-chat-message.f.mb12
    v-avatar(size="16px")
      img(:src="iconUser.photoURL")
    span(:class="{'is-active': messageIsActive}").message.px12.py8.mx8 {{content.message}}
    div(v-if="messageIsActive").f.flex-bottom
      span.time {{time}}
</template>

<style lang="scss" scoped>
.wrap-item-chat-message {
  .message {
    font-size: 14px;
    background: #eee;
    border-radius: 9px;

    transition: all 200ms ease-in;
    opacity: 0;
    transform: translateY(10px);
    &.is-active {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  .time {
    font-size: 10px;
  }
  &.is-mine {
    flex-direction: row-reverse;
    .message {
      color: #fff;
      background: #2a2a2a;
    }
  }
}
</style>

<script>
import format from 'date-fns/format'
import ja from 'date-fns/locale/ja'
import db from '@/components/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    trainer: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isMine: null,
      iconUser: null,
      messageIsActive: false,
      time: null
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created () {
    this.isMine = (this.content.createdBy === this.uid)
    this.iconUser = (this.trainer.uid === this.content.createdBy) ? this.trainer : this.client
    this.time = format(this.content.created.toDate(), 'kk:mm', { locale: ja })
  },
  mounted () {
    setTimeout(() => { this.messageIsActive = true }, 400)
  },
  methods: {

  }
}
</script>
