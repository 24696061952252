<template lang="pug">
  div.wrap-item-chat-input.f.fh
    div.input-chat.f.fm
      textarea(v-model="message" placeholder="メッセージを入力").px8.pt3.mr4
      v-icon(@click="sendMessage" :color="iconColor") send
</template>

<style lang="scss" scoped>
.wrap-item-chat-input {
  position: fixed;
  bottom: 48px;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 48px;
  background: #fff;
  .input-chat {
    width: 93%;
    max-width: 820px;
    margin: 0 auto;
    textarea {
      outline: none;
      resize: none;
      width: 95%;
      border: solid #999 1px;
      border-radius: 3px;
      height: 32px;
    }
  }
}
</style>

<script>
import db from '@/components/utils/firebase'

import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  props: {
    // data: {
    //   type: Object,
    //   default: ''
    // }
  },
  data () {
    return {
      message: '',
      iconColor: '#999'
    }
  },
  watch: {
    message (newMessage) {
      this.iconColor = (newMessage === '') ? '#999' : '#1a73e8'
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  methods: {
    async sendMessage () {
      if (this.iconColor === '#999') return false

      await db.collection('GROUPS')
        .doc(this.$route.params.groupId)
        .collection('MESSAGES')
        .add({
          message: this.message,
          type: 'text',
          createdBy: this.uid,
          created: new Date()
        })
      this.message = ''
    }
  }
}
</script>
