<template lang="pug">
  div(@click="onCard").wrap-item-record-card.f.flex-between.px20.py12
    div
      div.f.fm.mb8
        v-icon(v-if="content.type === 'karte'" color="indigo").mr4 description
        v-icon(v-if="content.type === 'consultation'" color="green").mr4 hearing
        v-icon(v-if="content.type === 'inbody'" color="orange").mr4 accessibility
        span.label.mr12 {{`${dateForLabel} | ${content.label}`}}
      span.summary.ml4 {{summary}}
    div(@click.stop v-if="!isClientView").f.fm
      v-menu(offset-y).mr2
        template(v-slot:activator="{ on }")
          div(v-on="on").add-record-button.f.fm.px6.py4
            v-icon more_vert
        v-list.px12.pt14.pb4.mt4
          div(@click='onCreateCopy').f.fm.mb12
            v-icon(size="16px").mr3 add
            span コピーして新規作成
          div(@click='onDeleteRecord').f.fm.mb12
            v-icon(size="16px").mr3 delete
            span 削除
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-item-record-card {
  border-bottom: $secondary_border;
  span {
    display: block;
  }
  .label {
    font-size: 18px;
  }
  .summary {
    color: #999;
  }
  .when {
    font-size: 12px;
    color: #999;
  }
  .memo {
    font-size: 14px;
  }
  .wrap-trainer {
    .wrap-img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>

<script>
import moment from 'moment'
import format from 'date-fns/format'
import ja from 'date-fns/locale/ja'

import db from '@/components/utils/firebase'
import { COLLECTIONS } from '@/enums'

import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
const { mapActions: mapActionsRecord } = createNamespacedHelpers('record')

export default {
  components: {
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    // for client view params
    isClientView: {
      type: Boolean,
      required: false
    },
    clientId: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      trainer: null,
      summary: '',
      memo: '',
      updatedAtStr: '',
      createdAtStr: '',
      dateForLabel: '',
      // toPath: null
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  watch: {
    'content': {
      handler: function () {
        this.adjustText()
      },
      deep: true
    }
  },
  async created () {
    this.adjustText()
  },
  methods: {
    ...mapActionsAuth(['getUserByUid']),
    ...mapActionsRecord(['deleteRecord']),
    async adjustText () {
      this.trainer = await this.getUserByUid(this.uid)

      let time
      if (this.content.type === 'karte') {
        if (this.content.trainingDate) {
          this.dateForLabel = format(this.content.trainingDate.toDate(), 'yyyy/MM/dd', { locale: ja })
          time = format(this.content.trainingDate.toDate(), 'kk:mm', { locale: ja })
        } else {
          this.dateForLabel = format(this.content.created.toDate(), 'yyyy/MM/dd', { locale: ja })
          time = format(this.content.updated.toDate(), 'kk:mm', { locale: ja })
        }
      } else {
        this.dateForLabel = format(this.content.created.toDate(), 'yyyy/MM/dd', { locale: ja })
        time = format(this.content.updated.toDate(), 'kk:mm', { locale: ja })
      }

      let typeText
      switch (this.content.type) {
        case 'karte':
          typeText = 'recorded karte'
          break
        case 'consultation':
          typeText = 'gave consultation'
          break
        case 'inbody':
          typeText = 'mesured inbody'
          break
      }

      this.summary = `${this.trainer.name} ${typeText} at ${time}`
    },
    onCard () {
      if (this.isClientView) {
        this.$router.push(`/client_record/${this.content.type}/${this.clientId}/${this.content.id}`)
      } else {
        this.$router.push(`/record/${this.content.type}/${this.$route.params.clientId}/${this.content.id}`)
      }
    },
    async onCreateCopy () {
      var record = await db.collection(COLLECTIONS.RECORDS)
        .doc(this.content.id)
        .get()
        .then(d => {
          return d.data()
        })

      record.label = `${record.label}のコピー`
      record.created = new Date()
      record.updated = new Date()

      if (this.content.type === 'karte') {
        record.body = record.body.map(e => {
          e.sets = []
          return e
        })
      }

      var docId = await db.collection(COLLECTIONS.RECORDS)
        .add(record)
        .then(d => {
          return d.id
        })

      this.$router.push(`/record/${record.type}/${this.$route.params.clientId}/${docId}`)
    },
    onDeleteRecord () {
      var doDelete = confirm(`${this.content.label}を削除しますか？`)
      if (doDelete) this.deleteRecord(this.content)
    }
  }
}
</script>
