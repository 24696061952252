<template lang="pug">
  div.wrap-module-chat
    div(v-if="messages" ref="chat").chat.pt12
      ItemChatMessage(
        v-for="item in messages"
        :content="item"
        :trainer="trainer"
        :client="client")
    ItemChatInput
</template>

<style lang="scss" scoped>
.wrap-module-chat {
  position: relative;
  background: #f9f9f9;
  min-height: calc(100vh - 48px * 2);
  .chat {
    width: 93%;
    height: 820px;
    height: calc(100vh - 48px * 2);
    margin: 0 auto;
    overflow: scroll;
    padding-bottom: 48px;
    scroll-behavior: smooth;
  }
}
</style>

<script>
import db from '@/components/utils/firebase'
import ItemChatMessage from '@/components/item/ItemChatMessage'
import ItemChatInput from '@/components/item/ItemChatInput'

export default {
  components: {
    ItemChatMessage,
    ItemChatInput
  },
  props: {
  },
  computed: {
    // isClient: function () {
    //   return (this.$route.name === 'client_inbox')
    // }
  },
  data () {
    return {
      group: null,
      messages: null,
      groupMember: null,
      trainer: null,
      client: null,
      isInitialized: false
    }
  },
  async mounted () {
    this.group = await db.collection('GROUPS')
      .doc(this.$route.params.groupId)
      .get()
      .then(d => {
        return d.data()
      })

    this.trainer = await db.collection('TRAINERS')
      .doc(this.group.createdBy)
      .get()
      .then(d => {
        var user = d.data()
        user.uid = d.id
        return user
      })

    this.client = await db.collection('CLIENTS')
      .doc(this.group.clientId)
      .get()
      .then(d => {
        var user = d.data()
        user.uid = d.id
        return user
      })

    db.collection('GROUPS')
      .doc(this.$route.params.groupId)
      .collection('MESSAGES')
      .orderBy('created', 'asc')
      .onSnapshot(this.getMessages)
  },
  methods: {
    getMessages (q) {
      this.messages = q.docs.map(d => {
        var data = d.data()
        data.id = d.id
        return data
      })
      if (this.isInitialized) {
        db.collection('GROUPS')
          .doc(this.$route.params.groupId)
          .update({ updated: new Date() })
      }
      setTimeout(this.toButtom, 800)
      this.isInitialized = true
    },
    toButtom () {
      if (this.$refs.chat) this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight
    }
  }
}
</script>
