<template lang="pug">
  transition(name="fade")
    div(v-if="showModal").wrap-modal-page
      HeaderModal(v-if="$route.name !== 'client_record'"
        @click.stop
        :rightIcon="headerRightIcon"
        @onLeft="headerOnLeft"
        @onRight="headerOnRight")
      div(@click.stop)
        ModuleSetRecord(ref="module"
          v-if="modalPageContentId === 'setRecord'"
          :autoSaveTrigger="autoSaveTrigger"
          @closeModal="() => { $emit('closeModalPage') }")
        ModuleSetConsultation(ref="module"
          v-if="modalPageContentId === 'setConsultation'"
          @closeModal="() => { $emit('closeModalPage') }")
        ModuleSetInbody(ref="module"
          v-if="modalPageContentId === 'setInbody'"
          @closeModal="() => { $emit('closeModalPage') }")
        ModuleChat(ref="module"
          v-if="modalPageContentId === 'chat'"
          @closeModal="() => { $emit('closeModalPage') }")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-modal-page {
  position: absolute;
  width: 100%;
  // min-height: 100vh;
  min-height: 100%;
  // min-height: calc(100% - 48px);
  left: 0;
  // top: 48px;
  padding-top: 48px;
  z-index: 101;
  background: $primary_bg_color;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-out;
  transform: translateX(0%);
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(100%);
}
</style>

<script>
import HeaderModal from '@/components/shared/HeaderModal'
import ModuleSetRecord from '@/components/module/ModuleSetRecord'
import ModuleSetConsultation from '@/components/module/ModuleSetConsultation'
import ModuleSetInbody from '@/components/module/ModuleSetInbody'
import ModuleChat from '@/components/module/ModuleChat'

export default {
  components: {
    HeaderModal,
    ModuleSetRecord,
    ModuleSetConsultation,
    ModuleSetInbody,
    ModuleChat
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    modalPageContentId: {
      type: String,
      required: true
    },
    headerRightIcon: {
      type: Object,
      default: null
    },
    headerLeftIcon: {
      type: Object,
      default: null
    },
    autoSaveTrigger: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    headerOnLeft() {
      this.$emit('headerOnLeft')
    },
    headerOnRight() {
      this.$refs.module.onHeaderRight()
    }
  }
}
</script>
